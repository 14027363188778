.modal-container {
  width: 50vw;
  height: 50vh;
  border-radius: 8px;
  z-index: 100;
  background-color: white;
  transform: translateY(-120vh);
  animation-name: animate;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  border-radius: 15px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  img {
    width: 100px;
    padding-left: 20px;
  }
  .modal-header {
    width: 100%;
    height: 10vh;
    background-color: black;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
  }

  .modal-body {
    padding: 0 50px 50px 50px;
  }
  .close-btn {
    color: white;
    font-size: 30px;
    position: absolute;
    right: 5%;
    cursor: pointer;
  }
  @keyframes animate {
    0% {
      transform: translateY(-100vh);
    }
    100% {
      transform: translateY(0);
    }
  }
  @media screen and (max-width: 1700px) {
    width: 50vw;
    height: 90vh;
    .modal-container {
      width: 100%;
      height: 100%;
    }
  }

  @media screen and (max-width: 962px) {
    width: 60vw;
    height: 70vh;
    .modal-container {
      width: 100%;
      height: 100%;
    }
    .custom-button {
      min-width: 150px;
      width: auto;
      height: 50px;
      margin:auto;
      letter-spacing: 0.5px;
      line-height: 50px;
      padding: 0 35px 0 35px;
      font-size: 15px;
      display:flex;
      justify-content: center;
      float:none
    }
  }
  @media screen and (max-width: 768px) {
    width: 60vw;
    height: 80vh;
    .modal-container {
      width: 100%;
      height: 100%;
    }
  }
  @media screen and (max-width: 480px) {
    width: 80vw;
    height: 85vh;
    .modal-container {
      width: 100%;
      height: 100%;
    }
  }
}
