  
body {
  font-family: 'Open Sans Condensed';
  padding: 20px 0px;
}

main {
  padding: 0 40px;
}

a {
  text-decoration: none;
  color: black;
}

* {
  box-sizing: border-box;
}