.navbar {
  position: relative;
  width: 100%;
  margin-top: 10px;
  border-bottom: 1px solid rgb(197, 195, 195);
  border-top: 1px solid rgb(197, 195, 195);
  padding: 15px 0;

  .navbar-brand {
    color: gray;
    font-size: 20px;
    margin-left: 55px;
    padding: 0 10px;
  }

  .navbar-brand:hover {
    background-color: rgba(231, 226, 230, 0.249);
    border-bottom: 1px solid rgb(197, 195, 195);
    padding: 2px;
    color: rgb(157, 153, 153);
    padding: 19px 10px;
    outline: none;
  }

  .navbar-nav {
    background-color: #fff;
    border-radius: 5px;
    color: rgb(0, 0, 0);
    padding: 10px;
    margin-top: 20px;
    width: 20%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .dropdown-item {
    font-size: 14px;
  }

  .navbar-toggler {
    position: relative;
  }

  @media screen and (min-width: 992px) {
    .navbar-nav {
      display: none !important;
    }
  }

  @media screen and (max-width: 991px) {
    .navmenu {
      display: none !important;
    }
  }

  @media screen and (max-width: 776px) {
    .navbar-nav {
      width: 130px;
    }
  }
}

.dropdown-item {
  font-size: 10px;
}