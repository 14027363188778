.checkout-page {
  width: 95%;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px auto 0;

  .checkout-header {
    width: 100%;
    padding: 40px 10px 20px 10px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid darkgrey;
    font-size: 20px;
    color: gray;

    .header-block {
      text-transform: capitalize;
      width: 23%;

      &:last-child {
        width: 4%;
      }
    }
  }

  .total {
    margin-top: 30px;
    margin-left: auto;
    font-size: 28px;
  }

  .test-warning {
    text-align: center;
    margin-top: 40px;
    font-size: 24px;
    color: red;
  }
  @media screen and (max-width: 768px) {
    .checkout-header {
      width: 100%;
      padding: 40px 36px 20px 0px;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid darkgrey;
      font-size: 12px;
      color: gray;

      .header-block {
        text-transform: capitalize;
        width: 23%;

        &:last-child {
          width: 4%;
        }
      }
    }
  }
  @media screen and (max-width: 480px) {
      width: 100%;
      min-height: 90vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 50px auto 0;

      .checkout-header {
        width: 100%;
        padding: 40px 25px 20px 0px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid darkgrey;
        font-size: 10px;
        color: gray;

        .header-block {
          text-transform: capitalize;
          width: 23%;

          &:last-child {
            width: 4%;
          }
        }
      }

      .total {
        margin-top: 30px;
        margin-left: auto;
        font-size: 20px;
      }
    
  }
}
