.homepage {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 120px 20px;
  margin: 0 2px;
}
@media screen and (max-width: 360px) {
  .homepage {
    padding: 20px 2px;
  }
}
@media screen and (max-width: 768px) {
  .homepage {
    padding: 20px 2px;
  }
}
@media screen and (max-width: 1800px) {
  .homepage {
    padding: 10px 2px;
  }
}

