.cart-icon {
  width: 35px;
  height: 55px;
  position: relative;
  top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .shopping-icon {
    width: 30px;
    height: 30px;
  }

  .item-count {
    position: absolute;
    top: 18px;
    font-size: 16px;
    font-weight: bold;
  }
}