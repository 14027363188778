.directory-menu {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  padding-top: 0;
}

@media screen and (min-width: 2536px) {
  .directory-menu {
    padding-top: 5%;
  }
}
