.collection-item {
  width: 80%;
  display: flex;
  flex-direction: column;
  height: 18vw;
  align-items: center;
  position: relative;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 5px;
  padding: 20px 0;
  .image {
    width: 90%;
    height: 90%;
    object-fit: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    margin: 5px;
    margin-bottom: 5px;
    transform: scale(0.8);
  }

  .custom-button {
    width: 95%;
    opacity: 0.7;
    position: absolute;
    bottom: 25%;
    display: none;
    border-radius: 0px;
  }

  &:hover {
    .image {
      opacity: 0.8;
      cursor: pointer;
    }

    .custom-button {
      opacity: 0.85;
      display: flex;
    }
  }

  .collection-footer {
    width: 90%;
    height: 30%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1vw;

    .name {
      width: 100%;
      margin-bottom: 15px;
      padding-left: 1%;
      white-space: wrap;
      font-weight: 900;
      font-size: 0.9em;
    }

    .price {
      width: 10%;
      padding-right: 20%;
      font-size: 0.9em;
      font-weight: bold;
    }
  }
  @media screen and (max-width: 1300px) {
    .custom-button {
      width: 75%;
      opacity: 0.7;
      position: absolute;
      top: 42%;
      display: none;
      font-size: 10px;
    }
    .collection-footer {
      width: 90%;
      height: 30%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 15px;

      .price {
        width: 10%;
        padding-right: 25%;
      }
    }
  }
  @media screen and (max-width: 1050px) {
    .custom-button {
      width: 75%;
      opacity: 0.7;
      position: absolute;
      top: 70px;
      display: none;
      font-size: 10px;
    }
    .collection-footer {
      width: 90%;
      height: 30%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 15px;

      .price {
        width: 10%;
        padding-right: 25%;
      }
    }
  }
  @media screen and (max-width: 912px) {
    width: 80%;
    height: 200px;

    .image {
      width: 90%;
      height: 80%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      margin-bottom: 5px;
    }

    .custom-button {
      width: 85%;
      opacity: 0.7;
      position: absolute;
      top: 100px;
      display: none;
      font-size: 10px;
    }
    &:hover {
      .image {
        opacity: 0.8;
      }

      .custom-button {
        opacity: 0.85;
        display: flex;
      }
    }
    .collection-footer {
      width: 90%;
      height: 5%;
      display: flex;
      justify-content: space-between;
      font-size: 1.5vw;
      padding: 20px 10px 10px 0;

      .name {
        width: 100%;
        margin-top: 15px;
        padding-left: 5px;
      }

      .price {
        width: 10%;
        padding-right: 20%;
      }
    }
  }
  @media screen and (max-width: 768px) {
    width: 27vw;
    display: flex;
    flex-direction: column;
    height: 200px;
    align-items: center;
    position: relative;

    .image {
      width: 90%;
      height: 80%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      margin-bottom: 2px;
      // margin-left: 20px;
      padding: 10px 0;
    }

    .collection-footer {
      width: 90%;
      height: 80px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: left;

      .name {
        width: 100%;
        margin-bottom: 5px;
        font-size: 2vw;
        display: flex;
        justify-content: left;
      }

      .price {
        width: 100%;
        font-size: 2vw;
        display: flex;
        justify-content: left;
        margin-left: 10px;
      }
    }
    .custom-button {
      width: 90%;
      opacity: 0.7;
      position: absolute;
      top: 100px;
      display: none;
      font-size: 10px;
      margin-left: 0;
    }
    &:hover {
      .image {
        opacity: 0.8;
      }

      .custom-button {
        opacity: 0.85;
        display: flex;
      }
    }
  }
  @media screen and (max-width: 480px) {
    width: 40vw;
    display: flex;
    flex-direction: column;
    height: 200px;
    align-items: center;
    position: relative;

    .image {
      width: 150px;
      height: 90%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      margin-bottom: 2px;
      // margin-left: 10px;
      padding: 10px 0px;
    }

    .collection-footer {
      width: 90%;
      height: 15%;
      display: flex;
      flex-direction: column;
      align-items: left;
      font-size: 16px;
      padding: 0;

      .name {
        width: 150px;
        margin-bottom: 15px;
        word-wrap: break-word;
        font-size: 2vw;
      }

      .price {
        width: 10%;
        font-size: 2vw;
      }
    }
    .custom-button {
      width: 50%;
      opacity: 0.7;
      position: absolute;
      top: 95px;
      display: none;
      font-size: 11px;
      padding: 0 10px;
      margin: 0;
    }
    &:hover {
      .image {
        opacity: 0.8;
      }

      .custom-button {
        opacity: 0.85;
        display: flex;
      }
    }
  }
  @media screen and (max-width: 380px) {
    
    .collection-footer {
      width: 80%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0;

      .name {
        width: 100px;
        word-wrap: break-word;
        font-size: 2vw;
      }
      .price{
        font-size: 3vw;
      }
    }
  }
}
