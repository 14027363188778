.collection-page {
  display: flex;
  flex-direction: column;
  padding-left: 5%;
  padding-top: 1%;

  .title {
    font-size: 38px;
    margin: 0 auto 30px;
  }

  .items {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 10px;

    & .collection-item {
      margin-bottom: 40px;
    }
  }
  @media screen and (max-width: 912px) {
    .items {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 10px;

      & .collection-item {
        margin-bottom: 30px;
      }
    }
  }
  @media screen and (max-width: 768px) {
    padding-left: 0px;

    .items {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 10px;

      & .collection-item {
        margin-bottom: 30px;
      }
    }
  }

  @media screen and (max-width: 480px) {
    padding-left: 0px;

    .items {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 10px;

      & .collection-item {
        margin-bottom: 30px;
      }
    }
  }
}
