.title-btn {
    background-color: transparent;
    border: none;
    outline: none;
    color: black;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: bold;
    display: inline-block;
    font-size: 16px;
}

  .item-btn{
    background-color: transparent ;
    border-color: black;
    outline: none;
    color: black;
    padding: 5px 10px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: bold;
    display: block;
    font-size: 12px;
    margin: 10px auto;

    &:hover{
        transform: scale(1.1);
    }
  }
