.footer {
    border-top: 1px solid rgba(128, 128, 128, 0.226);
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    font-family: "Cinzel";
    width: 100%;
    height: 100px;
    position: relative;
}

.footer-copyright{
    color: #af9471;
    font-size: 16px;
     @media screen and (max-width: 480px) {
        font-size: 14px;
  }
}

 