.header {
  height: 70px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
  padding: 0 40px;

  .dropdown-container {
    width: 6.5em;
    position: relative;
    top: 30px;
  }

  .dropdown-header {
    background: #ffffff;
    text-align: center;
    cursor: pointer;
    width: 30px;
    padding-left: 20px;
    .translate-icon {
      margin-bottom: 20px;
    }
  }

  .dropdown-list {
    padding: 0;
    margin: 0;
    padding-left: 1em;
    background: #ffffff;
    border: 2px solid #e5e5e5;
    box-sizing: border-box;
    width: 80px;
    position: relative;
    z-index: 100;

    &:first-child {
      padding-top: 0.5em;
      padding-right: 0.1em;
    }
  }

  .list-item {
    list-style: none;
    margin-bottom: 0.5em;

    button {
      width: 80%;
      font-size: 1rem;
      border: none;
      background-color: white;

      // border: 1px solid black;
      img {
        width: 2rem;
        height: auto;
      }
    }
  }

  .logo-container {
    height: 100%;
    width: 120px;
    margin-top: -20px;
    margin-left: -30px;
  }

  .logo {
    width: 150px;
    height: auto;
    padding-top: 1px;
    padding-left: 10px;
  }

  .options {
    width: 80%;
    height: 100%;
    padding: 20px 0px 0 40px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .option {
      padding: 10px;
      cursor: pointer;
    }
  }
}
