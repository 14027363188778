.sm-container {
    display: flex;
    flex-direction: column;
    // border: 3px solid #C2AB72;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    width: 40px;
    align-items: center;
    position: fixed;
    top: 350px;
    left: 0;
    z-index: 100;
    padding: 5px 0;

    a {
        margin: 5px 0;

    }
}

.sm-box {
    background-color: white;

    &:hover {
        transform: scale(1.1);
    }
}


#ig-icon{
    width: 20px;
    height: auto;
    color: #C2AB72;
    padding-left: 1px;

}
#fb-icon{
    width: 30px;
    height: auto;
    color: #C2AB72;
    padding-left: 1px;

}

#map-icon{
    width: 30px;
    height: auto;
    color: #C2AB72;
    padding-left: 1px;

}

#map-icon:hover{
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .sm-container {
        width: 30px;
    }

    #ig-icon{
        width: 15px;
        height: auto;
        color: #C2AB72;
        padding-left: 0;

    }
    #fb-icon{
        width: 20px;
        height: auto;
        color: #C2AB72;
        padding-left: 0;

    }
    
    #map-icon{
        width: 20px;
        height: auto;
        color: #C2AB72;
        padding-left: 0;

    }
    
  }

